
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";
import { downloadFileConfig } from "@/components/downloadFileConfig.js";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref({});

    const reports = ref(false);

    const farms = ref([]);
    const farm = ref(0);
    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const loading = ref(false);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(f: any) {
      const res = await http.get(
        `/api/picks?farm_id=${f}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o: any, d: any) => {
            const p: any = ps.find((or: any) => or.date == d);

            if (p === undefined) {
              return {
                ...o,
                [d]: a.reduce(
                  (r: any, v: any) => ({
                    ...r,
                    [v.id]: {
                      projected: 0
                    }
                  }),
                  {}
                )
              };
            } else {
              return {
                ...o,
                [d]: a.reduce(
                  (r: any, v: any) => ({
                    ...r,
                    [v.id]: {
                      projected:
                        ((p?.quantities as any)[v.id] as any)?.projected || 0
                    }
                  }),
                  {}
                )
              };
            }
          }, {});

          processing.value = false;
        });
      });
    }

    function sumPicks(v: any) {
      return getDates(start.value).reduce((o, d) => {
        const picks: any = (form.value as any)[d];
        const p = picks[v];
        return o + Number(p.projected || 0);
      }, 0);
    }

    function isFilled(v: any) {
      const total = getDates(start.value).reduce((o, d) => {
        const picks: any = (form.value as any)[d];
        const p = picks[v];
        return o + Number(p.projected || 0);
      }, 0);

      return reports.value ? total > 0 : true;
    }

    function searchVarieties() {
      fetchPicks(farm.value).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}`).then(res => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = getDates(start.value).reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected: (p?.quantities as any)[v.id]["projected"] || 0
                  }
                }),
                {}
              )
            };
          }, {});
        });
      });
    }

    function savePicks() {
      loading.value = true;
      http
        .post("/api/picks", {
          farm_id: farm.value,
          picks: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            loading.value = false;
            created.value = true;
          }
        })
        .finally(() => {
          loading.value = false;
          fetchVarieties(farm.value);
        });
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/varieties/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchVarieties(farm.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then(res => {
        fetchVarieties(farm.value);
      });
    }

    function downloadVolumeReport() {
      loading.value = true;
      http
        .get(
          `/api/reporting/weekly-volume-summary?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`,
          { responseType: "blob" }
        )
        .then(res => {
          downloadFileConfig(
            res,
            `Volume By Crop Report ${fD(start.value, "yyyy-MM-dd")} - ${fD(
              end.value,
              "yyyy-MM-dd"
            )} .xlsx`
          );
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm.value = Number(f);
        fetchVarieties(f);
      } else {
        farm.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => start.value,
      () => {
        fetchVarieties(farm.value);
      }
    );

    function updateForm() {
      fetchVarieties(farm.value);
    }

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      farm,
      farms,
      farmName,
      updateForm,
      sumPicks,
      isFilled,
      reports,
      loading,
      downloadVolumeReport
    };
  }
});
